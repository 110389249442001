.dashboard-item-content{
    > div {
        height: 100%;

        .ant-table.ant-table-bordered {
            height: 100%;

            .ant-table-container{
                height: 100%;
                
                .ant-table-content{
                    height: 100%;
                    position: relative;
                }
            }
        }
    }
}