.offer-item {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  text-align: center;
}

.offer-pin {
  border-bottom: 1px solid;
  transform: rotate(90deg);
  margin-left: 0px;
  margin-top: 8px;
  z-index: 1;
}
