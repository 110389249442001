.kpi-component{

    .kpi-value, .kpi-value-text{
        color: #000000d9;
        font-size: 24px;
        margin-left: 30px;
    }

    .divider{
        margin-bottom: 8px;
    }
}