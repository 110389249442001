.agent-list-container{
    .ant-table-wrapper{
        margin-left: 10px;
        margin-right: 10px;
    }
    .ant-table.ant-table-bordered {
        border-radius: 8px;
    }
}
.ant-row {
    display: flex;
    > .ant-table-wrapper{
        flex: 1;
    }
}

div.tooltip {
    position: absolute;
    text-align: center;
    color: black;
    width: 275px;
    height: 37px;
    padding: 2px;
    font: 12px sans-serif;
    background: grey;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}
