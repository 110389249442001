.lmnp-download-link {
  margin-top: -10px;
  margin-left: 15px;
  font-weight: bold;
}

.lmnp-download-div {
  width: 100%;
  height: 40px;
}

.lmnp_properties_table {
  padding-left: 20px;
  padding-right: 20px;
  .ant-table.ant-table-bordered {
    border-radius: 8px;
  }

  .ant-table-wrapper.lmnp-table {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table.ant-table-fixed-header {
          .ant-table-container {
            .ant-table-header {
              overflow: hidden !important;
            }
          }
        }
      }
    }
  }
}
