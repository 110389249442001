.dashboard-item-content > div.date-filter {
  position: absolute;
  left: 100px;
  top: 100px;
  display: flex;
  z-index: 2;
  height: initial;

  .date-filter-item {
    background: #f7f7f7;
    margin: 3px;
    padding: 4px;

    &.active {
      background: #e6ebf5;
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      background: #dbdbdb;
    }
  }
}
