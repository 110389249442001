.ant-table-wrapper.no-header{
    .ant-table-container{
        .ant-table-header{
            display: none;
        }
    }
}

.ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading{
        height: 100%;

        .ant-spin-container{
            height: 100%;

            .ant-table.ant-table-fixed-header{
                height: 100%;

                .ant-table-container{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    
                    .ant-table-header{
                        overflow: visible !important;
                    }
                }
            }
        }
    }
}