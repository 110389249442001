.card {
  background-color: white;
  transition: 0.3s;
  margin: auto;
  margin-top: 5%;
  width: 350px;
  height: 400px;
  border-radius: 10px;
}

.container {
  padding: 2px 16px;
}

.logout-icon {
  display: table;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 150px;
}

.buttons {
  display: table;
  margin: 5% auto;
}

.button {
  display: table;
  margin: 10% auto;
  width: 175px;
}

h3 {
  text-align: center;
}

h1 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: -30px;
}

.sextant-logo {
  height: 50px;
  align-self: center;
  margin-top: -5px;
  transform: rotate(346deg);
}
