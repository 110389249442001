.property-list-container{
    .ant-table-wrapper{
        margin-left: 10px;
        margin-right: 10px;
    }
    .ant-table.ant-table-bordered {
        border-radius: 8px;
    }
}

.ant-row {
    display: flex;
    > .ant-table-wrapper{
        flex: 1;
    }
}